@font-face {
  font-family: 'AGRoundedSTD';
  src: url('./../public/VAGRoundedStd-Bold.otf') format('truetype'); /* Adjust the path and format accordingly */
  font-weight: bold;
}


::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(200,200,200,1);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color:#fff;
  -webkit-box-shadow: inset 0 0 6px rgba(90,90,90,0.7);
}

.page-content {
  background-color: aliceblue;
}

.card-bordered {
  border: 1px solid #ebebeb;
}

.card {
  border: 0;
  border-radius: 0px;
  margin-bottom: 30px;
  -webkit-box-shadow: 0 2px 3px rgba(0,0,0,0.03);
  box-shadow: 0 2px 3px rgba(0,0,0,0.03);
  -webkit-transition: .5s;
  transition: .5s;
}

.card-header .card-title strong {
  font-family: 'AGRoundedSTD' !important;
}
.card-header .card-title strong img {
  padding-right: 5px;
}
.padding {
  padding: 3rem !important
}

body {
  background-color: #f9f9fa
}

.card-header:first-child {
  border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}


.card-header {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  padding: 15px 20px;
  background-color: #00ad77;
  border-bottom: 1px solid rgba(77,82,89,0.07);
  box-shadow: 0 8px 4px -2px rgba(0,0,0,.15);
}

.card-header .card-title {
  padding: 0;
  border: none;
}

h4.card-title {
  font-size: 17px;
}

.card-header>*:last-child {
  margin-right: 0;
}

.card-header>* {
  margin-left: 8px;
  margin-right: 8px;
}

.btn-secondary {
  color: #4d5259 !important;
  background-color: #e4e7ea;
  border-color: #e4e7ea;
  color: #fff;
}

.btn-xs {
  font-size: 11px;
  padding: 2px 8px;
  line-height: 18px;
}
.btn-xs:hover{
  color:#fff !important;
}




.card-title {
  font-family: Roboto,sans-serif;
  font-weight: 300;
  line-height: 1.5;
  margin-bottom: 0;
  padding: 15px 20px;
  border-bottom: 1px solid rgba(77,82,89,0.07);
}


.ps-container {
  position: relative;
  
}

#chat-content {
  background-image: url('./assets/logo_op.png') !important;
  background-size: 250px !important; /* Set the desired height and maintain aspect ratio */
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.ps-container {
  -ms-touch-action: auto;
  touch-action: auto;
  overflow: hidden!important;
  -ms-overflow-style: none;
}

.media-chat {
  padding-right: 64px;
  margin-bottom: 0;
}

.media {
  padding: 2px 12px;
  -webkit-transition: background-color .2s linear;
  transition: background-color .2s linear;
}

.media .avatar {
  flex-shrink: 0;
}

.avatar {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border-radius: 100%;
  background-color: #f5f6f7;
  color: #8b95a5;
  text-transform: uppercase;
}

.media-chat .media-body {
  -webkit-box-flex: initial;
  flex: initial;
  display: table;
  font-family: 'AGRoundedSTD' !important
  
}
.media-chat .block_line {
  display: flex;
  flex-direction: column;
}

.media-body {
  min-width: 0;
}

.media-chat .media-body p {
  position: relative;
  padding: 6px 8px;
  margin: 4px 0;
  border-radius: 3px;
  font-weight: 100;
  color:#9b9b9b;
  
}

.media>* {
  margin: 0 8px;
}

.media-chat .media-body p.meta {
  background-color: transparent !important;
  padding: 0;
  opacity: .8;
}

.media-meta-day {
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 0;
  color: #8b95a5;
  opacity: .8;
  font-weight: 400;
}


.media-meta-day::before {
  margin-right: 16px;
}

.media-meta-day::before, .media-meta-day::after {
  content: '';
  -webkit-box-flex: 1;
  flex: 1 1;
  border-top: 1px solid #ebebeb;
}

.media-meta-day::after {
  content: '';
  -webkit-box-flex: 1;
  flex: 1 1;
  border-top: 1px solid #ebebeb;
}

.media-meta-day::after {
  margin-left: 16px;
}

.media-chat.media-chat-reverse {
  padding-right: 12px;
  padding-left: 64px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  flex-direction: row-reverse;
}

.media-chat {
  padding-right: 64px;
  margin-bottom: 0;
}



.border-light {
  border-color: #f1f2f3 !important;
}

.bt-1 {
  border-top: 1px solid #ebebeb !important;
}


.text-info {
  color: #48b0f7 !important;
}


.form-control:focus {
    outline: none !important;
    border-color: #00ad77 !important;
    box-shadow: none !important;
}
.botColor {
  background-color: #36a070 !important;
}


.userButton {
  color: #00ad77 !important;
  border-color: #00ad77 !important;
  opacity: 1 !important;
}
.active {
  background-color: #00ad77 !important;
  color: white !important;
}
.userButton:hover {
  background-color: #00ad77 !important;
  color: white !important;
}

.userButton:focus {
  box-shadow: none;
}

.facebook-icon {
  border-color: #4267B2 !important;
  color: #4267B2 !important;
}
.facebook-icon:hover {
  background-color: #4267B2 !important;
  color: white !important;
}
.instagram-icon {
  border-color: #E4405F !important;
  color: #E4405F !important;
}
.instagram-icon:hover {
  background-color: #E4405F !important;
  color: white !important;
}
#recaptcha-container {
  display: none !important;
}
#recaptcha-container1 {
  display: none !important;
}
